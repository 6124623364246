var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            "max-width": "100%",
            margin: "auto",
            overflow: "hidden",
          },
          attrs: { id: "body-content-area" },
        },
        [
          _vm.isLoading
            ? _c("div", [_c("Loading")], 1)
            : _c("div", { staticStyle: { overflow: "hidden" } }, [
                _c("div", { attrs: { id: "pagelayout" } }, [
                  _c(
                    "div",
                    { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                    [
                      !_vm.isLoading
                        ? _c(
                            "div",
                            {
                              staticClass: "top-bar control grid-button",
                              staticStyle: { width: "20%" },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "button is-light",
                                  on: { click: _vm.clearFilters },
                                },
                                [_c("span", [_vm._v("Clear Filters")])]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "bottom-bar",
                          staticStyle: {
                            width: "80%",
                            display: "flex",
                            "justify-content": "flex-end",
                            "padding-bottom": "1rem",
                          },
                          attrs: { id: "buttongroup" },
                        },
                        [
                          _c("div", { staticClass: "level" }, [
                            _c("div", { staticClass: "level-left" }, [
                              _c("div", { staticClass: "field is-grouped" }, [
                                _c("div", { staticClass: "control" }, [
                                  _c(
                                    "a",
                                    {
                                      class: [
                                        "button is-accent",
                                        !_vm.$hasPermissions(
                                          _vm.clientSession,
                                          ["INSTITUTIONS"],
                                          2
                                        )
                                          ? "disabled"
                                          : "",
                                      ],
                                    },
                                    [
                                      _vm._m(0),
                                      _c(
                                        "span",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "has-text-white",
                                              attrs: {
                                                to: {
                                                  name: !_vm.$hasPermissions(
                                                    _vm.clientSession,
                                                    ["INSTITUTIONS"],
                                                    2
                                                  )
                                                    ? ""
                                                    : "NewInstitution",
                                                  params: {},
                                                },
                                              },
                                            },
                                            [_vm._v("Add Institution")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "control" }, [
                                  _c(
                                    "a",
                                    {
                                      class: [
                                        "button is-dark",
                                        !_vm.$hasPermissions(
                                          _vm.clientSession,
                                          ["INSTITUTIONS"],
                                          2
                                        )
                                          ? "disabled"
                                          : "",
                                      ],
                                      attrs: { disabled: !_vm.hasSelection },
                                      on: { click: _vm.prepDeletePrompt },
                                    },
                                    [
                                      _vm._m(1),
                                      _c("span", [
                                        _vm._v(
                                          "Delete Institution" +
                                            _vm._s(
                                              _vm.hasPluralSelection ? "s" : ""
                                            )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          style: {
                            maxHeight: _vm.maxHeight + "px",
                            height: _vm.maxHeight + "px",
                            width: "100%",
                          },
                        },
                        [
                          _vm.maxHeight > 0
                            ? _c("Grid", {
                                style: {
                                  maxHeight: _vm.maxHeight + "px",
                                  height: _vm.maxHeight + "px",
                                },
                                attrs: {
                                  filter: _vm.filter,
                                  "data-items": _vm.institutionItems,
                                  "selected-field": _vm.selectedField,
                                  sortable: true,
                                  sort: _vm.sort,
                                  filterable: true,
                                  pageable: Object.assign({}, _vm.pageable, {
                                    pageSizes: _vm.pageSizes,
                                  }),
                                  "page-size": _vm.pageSize,
                                  skip: _vm.skip,
                                  take: _vm.take,
                                  total: _vm.totalRecords,
                                  columns: _vm.columns,
                                },
                                on: {
                                  selectionchange: _vm.onSelectionChange,
                                  headerselectionchange:
                                    _vm.onHeaderSelectionChange,
                                  filterchange: _vm.filterChangeHandler,
                                  sortchange: _vm.sortChangeHandler,
                                  pagechange: _vm.pageChangeHandler,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "editTemplate",
                                      fn: function (ref) {
                                        var props = ref.props
                                        return [
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass: "bcc-color",
                                                  attrs: {
                                                    to: {
                                                      name: "EditInstitution",
                                                      params: {
                                                        id:
                                                          "" +
                                                          props.dataItem.id,
                                                        model:
                                                          "" +
                                                          JSON.stringify(
                                                            props.dataItem
                                                          ),
                                                        skip: "" + _vm.skip,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        props.dataItem.name
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "filterSlotTemplate",
                                      fn: function (ref) {
                                        var props = ref.props
                                        var methods = ref.methods
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "k-filtercell" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "k-filtercell-wrapper",
                                                },
                                                [
                                                  _c("input", {
                                                    staticClass: "k-textbox",
                                                    attrs: {
                                                      type: "text",
                                                      id: "" + props.field,
                                                    },
                                                    domProps: {
                                                      value: props.value,
                                                    },
                                                    on: {
                                                      input: function (ev) {
                                                        methods.change({
                                                          operator: "contains",
                                                          field: props.field,
                                                          value:
                                                            ev.target.value,
                                                          syntheticEvent: ev,
                                                        })
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "ActionCell",
                                      fn: function (ref) {
                                        var props = ref.props
                                        return [
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                width: "100%",
                                                padding: "0",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "action-cell" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "tooltip",
                                                          rawName:
                                                            "v-tooltip.left-start",
                                                          value: {
                                                            content:
                                                              "View Documents",
                                                            container: false,
                                                            classes: [
                                                              "tooltip",
                                                            ],
                                                          },
                                                          expression:
                                                            "{ content: 'View Documents', container: false, classes: ['tooltip'] }",
                                                          modifiers: {
                                                            "left-start": true,
                                                          },
                                                        },
                                                      ],
                                                    },
                                                    [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          staticClass: "link",
                                                          attrs: {
                                                            to: {
                                                              name: "InstutionsDocumentsScreen",
                                                              params: {
                                                                id:
                                                                  "" +
                                                                  props.dataItem
                                                                    .id,
                                                                model:
                                                                  "" +
                                                                  JSON.stringify(
                                                                    props.dataItem
                                                                  ),
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-file-alt",
                                                          }),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "tooltip",
                                                          rawName:
                                                            "v-tooltip.left-start",
                                                          value: {
                                                            content:
                                                              "Edit Institution",
                                                            container: false,
                                                            classes: [
                                                              "tooltip",
                                                            ],
                                                          },
                                                          expression:
                                                            "{ content: 'Edit Institution', container: false, classes: ['tooltip'] }",
                                                          modifiers: {
                                                            "left-start": true,
                                                          },
                                                        },
                                                      ],
                                                    },
                                                    [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          staticClass: "link",
                                                          attrs: {
                                                            to: {
                                                              name: "EditInstitution",
                                                              params: {
                                                                id:
                                                                  "" +
                                                                  props.dataItem
                                                                    .id,
                                                                model:
                                                                  "" +
                                                                  JSON.stringify(
                                                                    props.dataItem
                                                                  ),
                                                                skip:
                                                                  "" + _vm.skip,
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fas fa-pencil-alt",
                                                          }),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  931734400
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm.showDeleteModal
                    ? _c(
                        "div",
                        {
                          staticClass: "modal is-active",
                          attrs: { id: "delete-modal" },
                        },
                        [
                          _c("div", {
                            staticClass: "modal-background",
                            on: {
                              click: function ($event) {
                                _vm.showDeleteModal = false
                              },
                            },
                          }),
                          _c("div", { staticClass: "modal-card" }, [
                            _c(
                              "header",
                              { staticClass: "modal-card-head has-bg-danger" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "modal-card-title has-bg-danger",
                                  },
                                  [_vm._v("Confirm Delete")]
                                ),
                                _c("a", {
                                  staticClass: "delete",
                                  attrs: { "aria-label": "close" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showDeleteModal = false
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c("section", { staticClass: "modal-card-body" }, [
                              _vm.counts
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "10px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "By performing this action, you will delete the following users, reports, documents as well as the institution itself:"
                                        ),
                                        _c("br"),
                                        _c("br"),
                                        _vm._l(_vm.counts, function (count) {
                                          return _c(
                                            "div",
                                            {
                                              key: count.id,
                                              staticStyle: {
                                                width: "100%!important",
                                                padding: ".5rem 0!important",
                                              },
                                            },
                                            [
                                              _c(
                                                "b",
                                                {
                                                  staticStyle: {
                                                    "text-transform":
                                                      "capitalize",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getInstitution(
                                                        count.id
                                                      ).name
                                                    ) + ":"
                                                  ),
                                                ]
                                              ),
                                              _c("ul", [
                                                count.response
                                                  ? _c("li", [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            count.response
                                                              .userCount
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(" Users"),
                                                    ])
                                                  : _vm._e(),
                                                count.response
                                                  ? _c("li", [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            count.response
                                                              .reportCount
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(" Reports"),
                                                    ])
                                                  : _vm._e(),
                                                count.response
                                                  ? _c("li", [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            count.response
                                                              .documentCount
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(" Documents"),
                                                    ])
                                                  : _vm._e(),
                                              ]),
                                            ]
                                          )
                                        }),
                                      ],
                                      2
                                    ),
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "content" }, [
                                _c("br"),
                                _vm._v(' Type "'),
                                _c("b", [_vm._v("delete")]),
                                _vm._v(
                                  '" to confirm that you want to delete the institution' +
                                    _vm._s(_vm.hasPluralSelection ? "s" : "") +
                                    ". "
                                ),
                              ]),
                              _c("div", { staticClass: "field" }, [
                                _c("div", { staticClass: "control" }, [
                                  _c("input", {
                                    directives: [
                                      { name: "focus", rawName: "v-focus" },
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.confirmationText,
                                        expression: "confirmationText",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "input",
                                    attrs: {
                                      type: "text",
                                      placeholder:
                                        "type `delete` and then click confirm",
                                    },
                                    domProps: { value: _vm.confirmationText },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        $event.preventDefault()
                                        return _vm.deleteIfConfirmed.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.confirmationText =
                                          $event.target.value.trim()
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                            ]),
                            _c("footer", { staticClass: "modal-card-foot" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "button is-danger",
                                  attrs: {
                                    disabled: _vm.confirmationText !== "delete",
                                  },
                                  on: { click: _vm.deleteIfConfirmed },
                                },
                                [_vm._m(2), _c("span", [_vm._v("Confirm")])]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "button",
                                  on: {
                                    click: function ($event) {
                                      _vm.showDeleteModal = false
                                      _vm.selectedRows = []
                                    },
                                  },
                                },
                                [_vm._v("Cancel")]
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-plus-square" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-trash-alt" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-trash-alt" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }